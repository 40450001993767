import i18n from "."
// import { nextTick } from "vue"

const Trans = {
  async loadLocaleMessages(locale) {
    const messages = await import(  /* webpackChunkName: "locale-[request]" */ `@/i18n/locales/${locale}.json`)
    i18n.setLocaleMessage(locale, messages.default)
    // return nextTick()
  },
  getBrowserLocale() {
    const locale = window.navigator.language || window.navigator.userLanguage
    return {
      locale: locale,
      localeNoRegion: locale.split('-')[0]
    }
  },
  getPersistedLocale() {
    const persistedLocale = localStorage.getItem("language")
    if (persistedLocale == null) {
      return null;
    }
    switch (persistedLocale.toLocaleLowerCase()) {
      case 'rus': return 'ru';
      case 'pt': return 'pt';
      case 'es': return 'es';
      case 'ar': return 'ar';
      case 'fa': return 'fa';
      case 'tr': return 'tr';
      case 'eng':
      default: return 'en';
    }
  },
  get currentLocale() {
    return i18n.locale
  },
  set currentLocale(newLocale) {
    i18n.locale = newLocale
  },
  getUserLocale(){
    const persistedLocale = this.getPersistedLocale();
    return persistedLocale != null ? persistedLocale : this.getBrowserLocale().localeNoRegion;
  },
  async init() {
    const locale = this.getUserLocale()
    await this.loadLocaleMessages(locale);
    this.currentLocale = locale;
  }
}
export default Trans