import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store/index'
import api from "./api/api";

import LoginPage from "./pages/LoginPage";
import {getFolder} from "./api/api";
import Trans from './i18n/translation';

Vue.use(VueRouter);

const routes = [
    {
        path: '/login',
        name: 'login',
        components: {
            a: LoginPage
        },
    },
    {
        path: '/auto-login/:token',
        name: 'AutoLogin',
        beforeEnter: (to, from, next) => {
            if (store.getters.isAuthenticated) {
                next({name: "Home"});
            } else {
                api.auth(null, null, to.params.token)
                    .then(data => {
                        store.dispatch("login", data)
                            .then(() => {
                                next({name: "Home"})
                            })
                    })
                    .catch(error => {
                        alert("Произошла ошибка")
                    })
            }
        }
    },
    {
        path: '/list/:id/:elementId?/:action?',
        components: {
            a: () => import(/* webpackChunkName: "ListPage" */ "./pages/ListPage"),
            title: () => import(/* webpackChunkName: "ListTitle" */ "./components/list/ListTitle"),
            usersToolbar: () => import(/* webpackChunkName: "UsersToolbar" */ "./components/users-toolbar/UsersOfCatalog"),
            // viewSwitcher: () => import(/* webpackChunkName: "ListSwitcher" */ "./components/view-switcher/ListSwitcher"),
        },
        name: "List",
        props: {
            a: (route) => {
                let id = Number.parseInt(route.params.id, 10);
                if (Number.isNaN(id)) {
                    id = 0
                }
                let elementId = Number.parseInt(route.params.elementId, 10);
                if (Number.isNaN(elementId)) {
                    elementId = 0
                }
                let action = route.params.action;
                return {id, elementId, action}
            }
        },
        beforeEnter: (to, from, next) => {
            if (to.params.id == "-11") {
                next({name: "Calendar"})
            } else if (!store.getters.isAuthenticated) {
                next();
            } else {
                next();
            }
        }
    },
    {
        path: '/shared-lists',
        components: {
            a: () => import(/* webpackChunkName: "SharedListsPage" */ "./pages/SharedListsPage"),
            title: () => import(/* webpackChunkName: "ListTitle" */ "./components/SharedTitle"),
            // viewSwitcher: () => import(/* webpackChunkName: "HomeSwitcher" */ "./components/view-switcher/HomeSwitcher"),
            usersToolbar: () => import(/* webpackChunkName: "UsersToolbar" */ "./components/users-toolbar/UserFriends"),
        },
        name: "SharedLists",
    },
    {
        path: '/group/:id',
        components: {
            a: () => import(/* webpackChunkName: "GroupPage" */ "./pages/GroupPage"),
            title: () => import(/* webpackChunkName: "GroupTitle" */ "./components/GroupTitle"),
            // usersToolbar: () => import(/* webpackChunkName: "UsersToolbar" */ "./components/users-toolbar/UserFriends"),
        },
        name: "Group",
        props: {
            a: (route) => {
                let id = Number.parseInt(route.params.id, 10);
                if (Number.isNaN(id)) {
                    id = 0
                }
                return {id}
            },
            title: (route) => {
                let id = Number.parseInt(route.params.id, 10);
                if (Number.isNaN(id)) {
                    id = 0
                }
                return {id}
            }
        },
    },
    {
        path: '/list-access/:link',
        name: "ListAccess",
        beforeEnter: (to, from, next) => {
            if (!store.getters.isAuthenticated) {
                next();
            } else {
                const link = to.params.link;
                api.applyReferralLink(link)
                    .then(data => {
                        next({name: "List", params: {id: data.catalog_id}})
                    })
                    .catch(() => {
                        router.go(-1);
                    })
            }
        }
    },
    {
        path: '/add-to-friends/:link',
        name: "AddToFriends",
        beforeEnter: (to, from, next) => {
            //TODO when auth then move
            if (!store.getters.isAuthenticated) {
                next();
            } else {
                const link = to.params.link;
                api.applyAddToFriendsLink(link)
                    .then(data => {
                        next({name: "Home"});
                    })
                    .catch(() => {
                        router.go(-1);
                    })
            }
        }
    },
    {
        path: '/calendar/:catalog_id?',
        props: {
            a: (route) => {
                let catalogId = Number.parseInt(route.params.catalog_id, 10);
                if (Number.isNaN(catalogId)) {
                    catalogId = 0
                }
                return {catalogId}
            }
        },
        components: {
            a: () => import(/* webpackChunkName: "CalendarPage" */ "./pages/CalendarPage")
            //a: () => import(/* webpackChunkName: "CalendarPage" */ "./pages/CalendarPage")
        },
        name: "Calendar",
    },
    {
        path: '/mindmap/:catalog_id?',
        props: {
            a: (route) => {
                let catalogId = Number.parseInt(route.params.catalog_id, 10);
                if (Number.isNaN(catalogId)) {
                    catalogId = 0
                }
                return {catalogId}
            }
        },
        components: {
            a: () => import(/* webpackChunkName: "MindmapPage" */ "./pages/MindmapPage")
        },
        name: "Mindmap",
    },
    {
        path: '/mindmap-folder/:element_id',
        name: "MindmapFolder",
        beforeEnter: (to, from, next) => {
            if (!store.getters.isAuthenticated) {
                next();
            } else {
                const elementId = to.params.element_id;
                getFolder(elementId)
                    .then(response => {
                        next({name: "Mindmap", params : {catalog_id : response.data.id}});
                    })
                    .catch(error => {
                        next(vm => {
                            vm.prevRoute = from
                        })
                    })
            }
        }
    },
    {
        path: '/',
        components: {
            a: () => import(/* webpackChunkName: "HomePage" */ "./pages/HomePage"),
            // viewSwitcher: () => import(/* webpackChunkName: "HomeSwitcher" */ "./components/view-switcher/HomeSwitcher"),
            usersToolbar: () => import(/* webpackChunkName: "UsersToolbar" */ "./components/users-toolbar/UserFriends"),
        },
        name: "Home",
        props: {
            b: true
        },
    },
    {
        path: "/not-found",
        alias: "*",
        name: "NotFound",
        components: {
            a: () =>
                import(/* webpackChunkName: "NotFound" */
                    "./pages/NotFound")
        }
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: routes
});

router.beforeEach(
    async (to, from, next) => {
        store.dispatch('tryAutoLogin').then(() => {
            const isAuth = store.getters.isAuthenticated;
            if (to.name === "AutoLogin") {
                next();
            } else if (to.name === 'login') {
                if (isAuth) {
                    next({name: 'Home'})
                } else {
                    next();
                }
            } else {
                if (isAuth) {
                    next()
                } else {
                    next({name: 'login'});
                }
            }

        });
    }
);

export default router;
