import Vue from 'vue'
import Vuex from 'vuex'
import api from "../api/api";
import element from './element'
import list from './list'
import home from './home'
import playlist from "./playlist";
import view from "./view";
import form from "./form";
import notify from "./notify";
import router from "../router";
import avatar from "./avatar";
import viewOptions from "../viewOptions";

Vue.use(Vuex);

function generateTempToken(length) {
    let result = '';
    let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

const initialUser = {
    token: null,
    id: null,
    externalId: null,
    userName: null,
    chatId: null,
    firstName: null,
    lastName: null,
    imageUrl: null,
    language: null,
};

export default new Vuex.Store({
    modules: {
        element,
        list,
        home,
        playlist,
        view,
        form,
        notify,
        avatar
    },
    state: {
        user: initialUser,
        tempToken: null,
        options: {
            homeView: "how-bot",
            listView: "list"
        },
        flags: {
            isMobile: false,
        },
    },
    mutations: {
        setOptions(state, options) {
            state.options.homeView = options.homeView;
            state.options.listView = options.listView;
        },
        setOption(state, {option, value}) {
            state.options[option] = value;
        },
        authUser(state, userData) {
            state.user = userData;
            state.tempToken = null
        },
        clearAuthData(state) {
            state.user = initialUser;
            state.tempToken = null
        },
        setTempToken(state, tempToken) {
            state.tempToken = tempToken;
        },
        setImageUrl(state, imageUrl) {
            state.user.imageUrl = imageUrl;
        },
        setFlag(state, {flag, value}) {
            state.flags[flag] = value;
        },
        setBackButton(state, button) {
            state.backButton = button;
        },
    },
    actions: {
        setOption({commit}, {option, value}) {
            commit('setOption', {option, value});
            localStorage.setItem(option, value);
        },
        async login({commit, dispatch}, authUserInfo) {
            localStorage.setItem('token1', authUserInfo.token);
            localStorage.setItem('tenLvId', authUserInfo.id);
            localStorage.setItem('externalId', authUserInfo.externalId);
            localStorage.setItem('tenLvUserName', authUserInfo.userName);
            localStorage.setItem('tenLvFirstName', authUserInfo.firstName);
            localStorage.setItem('tenLvLastName', authUserInfo.lastName);
            localStorage.setItem('tenLvChatId', authUserInfo.chatId);
            localStorage.setItem('language', authUserInfo.language);
            localStorage.setItem('tenLv1TempToken', null);
            commit('authUser', authUserInfo);
        },
        async tryAutoLogin({state, commit}, reload) {
            if (localStorage.getItem('tenLv1TempToken') == null || localStorage.getItem('tenLv1TempToken') === 'null') {
                localStorage.setItem('tenLv1TempToken', generateTempToken(20))
            }
            commit('setTempToken', localStorage.getItem('tenLv1TempToken'));
            //first attempt
            //todo fix this epic
            if (!localStorage.getItem('token1')) {
                api.tempTokenAuth(localStorage.getItem('tenLv1TempToken'))
                    .then(data => {
                        localStorage.setItem('token1', data.token);
                        localStorage.setItem('tenLvId', data.id);
                        localStorage.setItem('externalId', data.externalId);
                        localStorage.setItem('tenLvUserName', data.userName);
                        localStorage.setItem('tenLvFirstName', data.firstName);
                        localStorage.setItem('tenLvLastName', data.lastName);
                        localStorage.setItem('tenLvChatId', data.chatId);
                        localStorage.setItem('language', data.language);
                        const token = localStorage.getItem('token1');
                        if (!token) {
                            return
                        }
                        const id = localStorage.getItem('tenLvId');
                        const externalId = localStorage.getItem('externalId');
                        const userName = localStorage.getItem('tenLvUserName');
                        const firstName = localStorage.getItem('tenLvFirstName');
                        const lastName = localStorage.getItem('tenLvLastName');
                        const chatId = localStorage.getItem('tenLvChatId');
                        const imageUrl = localStorage.getItem('tenLvImageUrl');
                        const language = localStorage.getItem('language');
                        let homeView = localStorage.getItem('homeView');
                        if (!homeView) {
                            homeView = viewOptions.defaultHomeViewOption(state.user.language);
                        }
                        let listView = localStorage.getItem('listView');
                        if (!listView) {
                            listView = viewOptions.defaultListViewOption(state.user.language);
                        }
                        commit('authUser', {
                            token,
                            id,
                            externalId,
                            userName,
                            firstName,
                            lastName,
                            chatId,
                            imageUrl,
                            language,
                        });
                        commit('setOptions', {
                            homeView,
                            listView
                        });
                        if (reload) {
                            router.go(0);
                        }
                    })
                    .catch(status => {
                        console.log('temp-token is missing or outdated')
                    });
            } else {
                const token = localStorage.getItem('token1');
                if (!token) {
                    return
                }
                const id = localStorage.getItem('tenLvId');
                const externalId = localStorage.getItem('externalId');
                const userName = localStorage.getItem('tenLvUserName');
                const firstName = localStorage.getItem('tenLvFirstName');
                const lastName = localStorage.getItem('tenLvLastName');
                const chatId = localStorage.getItem('tenLvChatId');
                const imageUrl = localStorage.getItem('tenLvImageUrl');
                const language = localStorage.getItem('language');
                let homeView = localStorage.getItem('homeView');
                if (!homeView) {
                    homeView = viewOptions.defaultHomeViewOption(state.user.language);
                }
                let listView = localStorage.getItem('listView');
                if (!listView) {
                    listView = viewOptions.defaultListViewOption(state.user.language);
                }
                commit('authUser', {
                    token,
                    id,
                    externalId,
                    userName,
                    firstName,
                    lastName,
                    chatId,
                    imageUrl,
                    language,
                });
                commit('setOptions', {
                    homeView,
                    listView
                });
            }
        },

        async loginByGoogle({state, commit}, authInfo) {
            console.log(authInfo);
            localStorage.setItem('token1', authInfo.token);
            localStorage.setItem('tenLvId', authInfo.id);
            localStorage.setItem('externalId', authInfo.externalId);
            localStorage.setItem('tenLvUserName', authInfo.userName);
            localStorage.setItem('tenLvFirstName', authInfo.firstName);
            localStorage.setItem('tenLvLastName', authInfo.lastName);
            localStorage.setItem('tenLvChatId', authInfo.chatId);
            localStorage.setItem('language', authInfo.language);
            const token = localStorage.getItem('token1');
            const id = localStorage.getItem('tenLvId');
            const externalId = localStorage.getItem('externalId');
            const userName = localStorage.getItem('tenLvUserName');
            const firstName = localStorage.getItem('tenLvFirstName');
            const lastName = localStorage.getItem('tenLvLastName');
            const chatId = localStorage.getItem('tenLvChatId');
            const imageUrl = localStorage.getItem('tenLvImageUrl');
            const language = localStorage.getItem('language');
            let homeView = localStorage.getItem('homeView');
            if (!homeView) {
                homeView = viewOptions.defaultHomeViewOption(state.user.language);
            }
            let listView = localStorage.getItem('listView');
            if (!listView) {
                listView = viewOptions.defaultListViewOption(state.user.language);
            }
            commit('authUser', {
                token,
                id,
                externalId,
                userName,
                firstName,
                lastName,
                chatId,
                imageUrl,
                language,
            });
            commit('setOptions', {
                homeView,
                listView
            });
            router.go(0);
        },

        logout({commit}) {
            commit('clearAuthData');
            localStorage.removeItem('token1');
            localStorage.removeItem('tenLvUserName');
            localStorage.removeItem('tenLvFirstName');
            localStorage.removeItem('tenLvLastName');
            localStorage.removeItem('externalId');
            localStorage.removeItem('tenLvChatId');
            localStorage.removeItem('tenLvImageUrl');
            localStorage.removeItem('language');
            localStorage.setItem('tenLv1TempToken', generateTempToken(20));
            location.reload();
        },

        async setUserImage({commit}) {
            if (localStorage.getItem('tenLvImageUrl') !== null) {
                return;
            }
            const externalId = localStorage.getItem('externalId');
            if (externalId == null) {
                return;
            }
            const path = 'images/' + externalId + '/userpic.jpg';
            api.uploadUserImage()
                .then(() => {
                    commit('setImageUrl', path);
                    localStorage.setItem('tenLvImageUrl', path);
                })
                .catch(() => {
                    commit('setImageUrl', null);
                });
        },

        setFlag({commit}, {flag, value}) {
            commit('setFlag', {flag, value});
        },
    },
    getters: {
        isAuthenticated(state) {
            return state.user.token != null
        },
        chatId(state) {
            return state.user.chatId;
        },
        token(state) {
            return state.user.token;
        },
        externalId(state) {
            return state.user.externalId;
        },
        language(state) {
            return state.user.language;
        },
        userName(state) {
            if (state.user.userName != null) {
                return state.user.userName;
            } else if (state.user.firstName != null) {
                return state.user.firstName;
            } else {
                return "Вы";
            }
        },
        homeViewOptions(state) {
            return viewOptions.homeViewOptions(state.user.language)
        },
        listViewOptions(state) {
            return viewOptions.listViewOptions(state.user.language)
        },
    }
})
