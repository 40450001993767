import axios from "axios";
import store from "../store/index";
import configs from "../config";

export const instance = axios.create({
  baseURL: configs.apiBaseURL,
});

export const wss = (path) => {
  let socket;
  const createSocket = () => {
    const ws = new WebSocket(`wss://api.ten.lv${path}/${store.getters.token}`);

    ws.onclose = (event) => {
      console.error("WebSocket connection closed:", event);
      setTimeout(createSocket, 1000); // Reconnect after 1 second
    };

    ws.onerror = (error) => {
      console.error("WebSocket error:", error);
    };
    return ws;
  };

  socket = createSocket();
  return socket;
};

export const getUsersOnline = (id) => {
  return instance.get(
    `/mindmap/${id}/users-online?token=${store.getters.token}`
  );
};

export const getGroupChatsOfUser = () => {
  return instance.get(`/user/getGroupChatsOfUser/`, {
    params: { token: store.getters.token },
  });
};

export const changeGroupSettings = (context, groupId, visible) => {
  return instance.patch(`/user/changeGroupSettings/`, {
    token: store.getters.token,
    groupId,
    visible,
    context,
  });
};

export const copyNode = (id, nodeId, parentNodeId) => {
  return instance.patch(`/mindmap/${id}/copy`, {
    token: store.getters.token,
    nodeId,
    parentNodeId,
  });
};

export const cutNode = (id, nodeId, parentNodeId) => {
  return instance.patch(`/mindmap/${id}/cut`, {
    token: store.getters.token,
    nodeId,
    parentNodeId,
  });
};

export const appendNode = (id, parentId, text) => {
  return instance.post(`/mindmap/${id}/append`, {
    token: store.getters.token,
    parentId,
    text,
  });
};

export const appendToMindMap = (id, parentId, point) => {
  if (parentId) point = null;
  return instance.post(`/mindmap/${id}/append`, {
    token: store.getters.token,
    parentId,
    point,
  });
};

export const deleteFromMindMap = (id, nodeId) => {
  return instance.delete(`/mindmap/${id}/delete`, {
    params: { token: store.getters.token, nodeId },
  });
};

export const getFolder = (elementId) => {
  return instance.get(`/element/getFolder/`, {
    params: {
      token: store.getters.token,
      elementId,
    },
  });
};

export const getMindMapNode = (elementId) => {
  return instance.get(`/mindmap/node/${elementId}`, {
    params: {
      token: store.getters.token,
    },
  });
};

export const getMindMap = (id) => {
  return instance.get(`/mindmap/${id}`, {
    params: {
      token: store.getters.token,
    },
  });
};

export const updateMindMapNode = (id, nodeId, fx, fy, opened, nodeText) => {
  return instance.patch(`/mindmap/${id}`, {
    token: store.getters.token,
    nodeId,
    fx,
    fy,
    opened,
    nodeText,
  });
};

export const setMindMapNodeColor = (id, nodeId, color) => {
  return instance.patch(`/mindmap/${id}/color`, {
    token: store.getters.token,
    nodeId,
    color,
  });
};

export const zoomMindMap = (
  id,
  viewBox,
  transformX,
  transformY,
  transformK
) => {
  return instance.patch(`/mindmap/${id}/view-box`, {
    token: store.getters.token,
    viewBox,
    transformX,
    transformY,
    transformK,
  });
};

/* не используем */
export const getMindMapViewBox = (id) => {
  return instance.get(`/mindmap/${id}/view-box`, {
    params: {
      token: store.getters.token,
    },
  });
};

export const changeUserLanguage = (language) => {
  return instance.patch(`/user/changeUserLanguage`, {
    token: store.getters.token,
    language,
  });
};

export default {
  auth(externalId, tempPassword, token) {
    return new Promise((resolve, reject) => {
      instance
        .post("/user/auth", {
          externalId,
          tempPassword,
          token,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.status));
    });
  },
  getListsOfUser(userName, externalId) {
    return new Promise((resolve, reject) => {
      instance
        .get(`/user/getListsOfUser`, {
          params: { token: store.getters.token, userName, externalId },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.status));
    });
  },
  tempTokenAuth(tempToken) {
    return new Promise((resolve, reject) => {
      instance
        .post("/user/tempTokenAuth", {
          tempToken,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.status));
    });
  },
  uploadUserImage(userId) {
    return new Promise((resolve, reject) => {
      instance
        .post("/user/uploadUserImage", {
          token: store.getters.token,
          userId,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.status));
    });
  },
  getElement(id) {
    return new Promise((resolve, reject) => {
      instance
        .get(`/element/get/${store.getters.token}/${id}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.status));
    });
  },
  addElement(element, catalogId, columnId) {
    return new Promise((resolve, reject) => {
      instance
        .post("/element/add", {
          token: store.getters.token,
          element,
          catalogId,
          columnId,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  updateElement(element) {
    return new Promise((resolve, reject) => {
      instance
        .post("/element/update", {
          token: store.getters.token,
          element,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  addElementToCalendar(element) {
    return new Promise((resolve, reject) => {
      instance
        .post("/element/addToCalendar", {
          token: store.getters.token,
          element,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  updateElementText(elementId, text) {
    return new Promise((resolve, reject) => {
      instance
        .post("/element/updateText", {
          token: store.getters.token,
          elementId,
          text,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  deleteElement(elementId) {
    return new Promise((resolve, reject) => {
      instance
        .delete(`/element/delete/${store.getters.token}/${elementId}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.status));
    });
  },
  getList(id, getElements) {
    return new Promise((resolve, reject) => {
      instance
        .get(`/catalog/get`, {
          params: {
            token: store.getters.token,
            id,
            getElements,
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.status));
    });
  },
  getElementsOfList(cataloId) {
    return new Promise((resolve, reject) => {
      instance
        .get(`/catalog/getElementsOfList/${store.getters.token}/${cataloId}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.status));
    });
  },

  changeElementOrder(catalogId, elementId, columnId, oldIndex, newIndex) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/catalog/changeElementOrder/`, {
          token: store.getters.token,
          catalogId,
          elementId,
          columnId,
          oldIndex,
          newIndex,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.status));
    });
  },

  changeElementColumn(catalogId, elementId, columnId, newIndex) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/catalog/changeElementStatus/`, {
          token: store.getters.token,
          catalogId,
          elementId,
          columnId,
          newIndex,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.status));
    });
  },
  updateList(catalog) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/catalog/update/`, {
          token: store.getters.token,
          catalog,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.status));
    });
  },
  changeCatalogOrder(externalId, catalogIdList) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/user/changeCatalogOrder/`, {
          token: store.getters.token,
          externalId,
          catalogIdList,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.status));
    });
  },
  getAudio(fileId) {
    return new Promise((resolve, reject) => {
      instance
        .get(`/element/getAudio/${store.getters.token}/${fileId}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.status));
    });
  },
  getImage(fileId) {
    return new Promise((resolve, reject) => {
      instance
        .get(`/element/getImage/${store.getters.token}/${fileId}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.status));
    });
  },
  getSharedListsOfUser() {
    return new Promise((resolve, reject) => {
      instance
        .get(`/user/getSharedCatalogsOfUser/${store.getters.token}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.status));
    });
  },
  getElementColors() {
    return new Promise((resolve, reject) => {
      instance
        .get(`/element/getElementColors/${store.getters.token}`)
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response.status));
    });
  },
  setElementColor(elementId, colorId) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/element/setColorOfElementCommand/`, {
          token: store.getters.token,
          elementId,
          colorId,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  clearColorOfElement(elementId) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/element/clearColorOfElementCommand/`, {
          token: store.getters.token,
          elementId,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  createListCommand(name, parentId, columnId) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/catalog/create/`, {
          token: store.getters.token,
          name,
          parentId,
          columnId,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  addListColumnCommand(name, catalogId) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/catalog/addColumn/`, {
          token: store.getters.token,
          name,
          catalogId,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  updateCatalogColumnName(name, columnId, catalogId) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/catalog/updateCatalogColumnName/`, {
          token: store.getters.token,
          name,
          columnId,
          catalogId,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  shiftCatalogColumn(columnId, toRight) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/catalog/shiftCatalogColumn/`, {
          token: store.getters.token,
          name,
          columnId,
          toRight,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  deleteCatalogColumn(id) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/catalog/deleteCatalogColumn/`, {
          token: store.getters.token,
          id,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  moveElementsToCatalog(elements, fromCatalogId, toCatalogId, copy) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/element/moveElementsToCatalog/`, {
          token: store.getters.token,
          elements,
          fromCatalogId,
          toCatalogId,
          copy,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  createReferralLink(catalogId, canEdit) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/catalog/createReferralLink/`, {
          token: store.getters.token,
          catalogId,
          canEdit,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  applyReferralLink(referralLink) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/catalog/applyReferralLink/`, {
          token: store.getters.token,
          referralLink,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  getReferralLinksOfCatalog(catalogId) {
    return new Promise((resolve, reject) => {
      instance
        .get(`/catalog/getReferralLinksOfCatalog/`, {
          params: {
            token: store.getters.token,
            catalogId,
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  clearReferralLinksOfCatalog(catalogId) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/catalog/clearReferralLinksOfCatalog/`, {
          token: store.getters.token,
          catalogId,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  getCatalogUsers(catalogId, limit) {
    return new Promise((resolve, reject) => {
      instance
        .get(`/catalog/getCatalogUsers`, {
          params: {
            token: store.getters.token,
            catalogId,
            limit,
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  getCatalogsUsers(catalogIds, limit) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/catalog/getCatalogsUsers`, {
          token: store.getters.token,
          catalogIds,
          limit,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  removeCatalogRole(catalogId, userId) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/catalog/removeCatalogRole/`, {
          token: store.getters.token,
          catalogId,
          userId,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  changeCatalogRole(catalogId, userId, up) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/catalog/changeCatalogRole/`, {
          token: store.getters.token,
          catalogId,
          userId,
          up,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  getUserFriends() {
    return new Promise((resolve, reject) => {
      instance
        .get(`/user/getUserFriends/`, {
          params: {
            token: store.getters.token,
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  getFriendsOfUser() {
    return new Promise((resolve, reject) => {
      instance
        .get(`/user/getFriendsOfUser/`, {
          params: {
            token: store.getters.token,
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  addFriendOfUser(userId) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/user/addFriendOfUser/`, {
          token: store.getters.token,
          userId,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  deleteFriendOfUser(userId) {
    return new Promise((resolve, reject) => {
      instance
        .delete(`/user/deleteFriendOfUser/`, {
          params: {
            token: store.getters.token,
            userId,
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  getUserNotifications(skip, take) {
    return new Promise((resolve, reject) => {
      instance
        .get(`/user/getUserNotifications/`, {
          params: {
            skip,
            take,
            token: store.getters.token,
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  getUnreadNotificationCountOfUser() {
    return new Promise((resolve, reject) => {
      instance
        .get(`/user/getUnreadNotificationCountOfUser/`, {
          params: {
            token: store.getters.token,
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  markNotificationAsRead(notificationId) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/user/markNotificationAsRead/`, {
          token: store.getters.token,
          notificationId,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  addUsersToCatalog(catalogId, users) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/catalog/addUsersToCatalog/`, {
          token: store.getters.token,
          catalogId,
          users,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  authGoogle(email) {
    return new Promise((resolve, reject) => {
      instance
        .get(`/user/authGoogle/`, {
          params: {
            token: store.getters.token,
            email,
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  uploadImage(file) {
    return new Promise((resolve, reject) => {
      file.append("token", store.getters.token);
      instance
        .post("/element/uploadImage", file, {
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=----WebKitFormBoundarymx2fSWqWSd0OxQqq",
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  uploadDocument(file) {
    return new Promise((resolve, reject) => {
      file.append("token", store.getters.token);
      instance
        .post("/element/uploadDocument", file, {
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=----WebKitFormBoundarymx2fSWqWSd0OxQqq",
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  downloadDocument(fileUrl, fileName) {
    return new Promise((resolve, reject) => {
      instance
        .post(
          "/file/downloadDocument",
          { token: store.getters.token, fileUrl, fileName },
          {
            responseType: "arraybuffer",
            contentType: "application/json; charset=utf-8",
            dataType: "json",
          }
        )
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  getLinkForAddingToFriends() {
    return new Promise((resolve, reject) => {
      instance
        .get(`/user/getLinkForAddingToFriends/`, {
          params: {
            token: store.getters.token,
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  applyAddToFriendsLink(link) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/user/applyAddToFriendsLink/`, {
          token: store.getters.token,
          link,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  uploadCatalogCover(file) {
    return new Promise((resolve, reject) => {
      file.append("token", store.getters.token);
      instance
        .post("/catalog/uploadCover", file, {
          headers: {
            "Content-Type":
              "multipart/form-data; boundary=----WebKitFormBoundarymx2fSWqWSd0OxQqq",
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },

  setCatalogCover(catalogId, imageId) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/catalog/setCatalogCover/`, {
          token: store.getters.token,
          catalogId,
          imageId,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },

  setCatalogTheme(catalog_id, catalog_theme) {
    return new Promise((resolve, reject) => {
      instance
        .post(`/catalog/setCatalogTheme/`, {
          token: store.getters.token,
          catalog_id,
          catalog_theme,
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  getCalendarElements(catalogId) {
    return new Promise((resolve, reject) => {
      instance
        .get(`/user/GetCalendarElements/`, {
          params: {
            token: store.getters.token,
            catalogId,
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
  getGroup(groupId) {
    return instance.get(`/user/getGroup/`, {
      params: {
        token: store.getters.token,
        groupId,
      },
    });
  },
  getUserContactsOfUser(contextCatalogId) {
    return new Promise((resolve, reject) => {
      instance
        .get(`/user/getUserContactsOfUser/`, {
          params: {
            token: store.getters.token,
            contextCatalogId,
          },
        })
        .then((response) => resolve(response.data))
        .catch((error) => reject(error.response));
    });
  },
};
